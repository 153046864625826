import * as React from "react";
import { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { ApiErrorActionType } from "../../../../ErrorBoundary";
import { store } from "../../../../shared/redux/store";
import { APIClientFactory } from "../../../../shared/utils/APIClientFactory";
import { CustomerResumeContactRequest } from "../../../../shared/utils/DataAccess";
import logo from "../../../../assets/images/logo-dark.svg";


const ContactPage = () => 
{
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [commentError, setCommentError] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");


    const onNameChange = (e: any) =>
    {
        setName(e.target.value);
    }

    const onEmailChange = (e: any) =>
    {
        setEmail(e.target.value);
    }

    const onPhoneChange = (e: any) =>
    {
        setPhone(e.target.value);
    }

    const onCommentChange = (e: any) =>
    {
        setComment(e.target.value);
    }

    const onSubmit = (e: any) =>
    {
        e.preventDefault();
        e.stopPropagation();

        setNameError(name.length === 0);
        setEmailError(email.length === 0);
        setPhoneError(phone.length === 0);

        if (name.length === 0 || email.length === 0 || phone.length === 0)
        {   //validation errors
            return;
        }

        const slug: string = "testuser";

        const request: CustomerResumeContactRequest = CustomerResumeContactRequest.fromJS({
            name: name,
            email: email,
            phone: phone,
            comment: comment
        });

        APIClientFactory.getInstance()
            .createResumeContact(slug, request)
            .then(() =>
            {
                setMessageSent(true);
            })
            .catch((e: any) =>
            {
                store.dispatch({ type: ApiErrorActionType, payload: e });
            });
    }

    return (
        <>
            <style>{"body { background-color: #ffffff; }"}</style>
            <div className="resume-builder">
                <header className={"site-header"}>
                    <div className="container">
                        <div className="logo">
                            <a href="#"><img id="main-logo" src={logo} alt="" className="logo" /></a>
                        </div>
                    </div>
                </header>
            </div>
            <section className="s-py-half">
                <div className="container">
                    <div className="light-blue-bg contact-form">
                        <div className="title">
                            <h2>Contact me</h2>
                            <p>The information captured will be sent directly to the resume owner by email.</p>
                        </div>
                        <form>
                            <div className="form-msg">
                                <p className="success-msg" style={{display: messageSent ? "initial" : "none"}}>The message has been sent successfully.</p>
                                <p className="error-msg" style={{display: (nameError || emailError || phoneError) ? "initial" : "none"}}>There are items that require your attention.</p>
                            </div>
                            <ul className="form-list row">
                                <li>
                                    <div className="row">
                                        <div className="col-4">
                                            <input id="Name" type="text" name="" className={"text-field" + (nameError ? " error" : "")} placeholder="Name" value={name} onChange={onNameChange} />
                                            <p className="error-msg" style={{display: nameError ? "inherit" : "none"}}>Please enter your name.</p>
                                        </div>
                                        <div className="col-4">
                                            <input id="Email" type="text" name="" className={"text-field" + (emailError ? " error" : "")} placeholder="Email" value={email} onChange={onEmailChange} />
                                            <p className="error-msg" style={{display: emailError ? "inherit" : "none"}}>Please enter your email address.</p>
                                        </div>
                                        <div className="col-4">
                                            <input id="Phone" type="text" name="" className={"text-field" + (phoneError ? " error" : "")} placeholder="Phone" value={phone} onChange={onPhoneChange} />
                                            <p className="error-msg" style={{display: phoneError ? "inherit" : "none"}}>Please enter your phone number.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <textarea id="" name="" className={"text-field" + (commentError ? " error" : "")} placeholder="Comment" onChange={onCommentChange}>{comment}</textarea>
                                    <p className="error-msg" style={{display: commentError ? "inherit" : "none"}}>Please enter a comment.</p>
                                </li>
                                <li>
                                    <button className="blue-btn small" onClick={onSubmit} style={{display: messageSent ? "none" : "initial"}}>Submit</button>
                                </li>
                            </ul>
                            <div className="disclaimer">
                                <p>By submitting your information through this contact form, you agree to share your information with the owner of the associated online resume and FastResume. This form is strictly intended for use to contact the online resume owner. FastResume.com will not sell, rent, lease, or distribute your personal information to any third party.</p>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <footer className="site-footer">
                <div className="container">
                    <p>© 2022 Fast Resume. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    );
};


export default ContactPage;