import * as React from "react";
import { useEffect, useState } from "react";
import config from "../../../config";


const SlugLayout = () => 
{
    const url = window.location.href;
    const resumeId: string = url.substring(url.lastIndexOf("/") + 1);
    const previewURL = config.apiPath + "/v1/resume/" + resumeId;

    useEffect(() => 
    {
        document.body.className = "slug-page";
    }, []);


    /*
        Need to use this to allow the cross origin iframe to push information back to us
        We need from the following information from the iframe
            document height (so we can properly allow user to scroll)
            document title (so we can display here as well)
    */
    let eventMethod: any = (window.addEventListener) ? "addEventListener" : "attachEvent";
    let eventer: any = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    if (typeof eventer === "function")
    {
        eventer(messageEvent, function(e: any) 
        {
            if (!e.data.documentInfo) return;   // check for our data structure

            document.getElementById("test")!.style.height = e.data.documentInfo.height + "px";
            document.getElementById("test")!.parentElement!.style.height = e.data.documentInfo.height + "px";
            document.title = e.data.documentInfo.title;
        }, false);    
    }

    return (
        <iframe id="test" className="resumePreviewWindow" src={previewURL} frameBorder="0" scrolling="no"></iframe>
    );

};
export default SlugLayout;
