import * as React from "react";
import { ResumeColorInformation } from "../../../shared/utils/DataAccess";


interface ColorItemProps
{
    selectedResumeColorID: number,
    color: ResumeColorInformation,
    selected: (id: number) => void
}


const ColorItem = (props: ColorItemProps) => 
{

    const onTemplateSelected = (e: any) =>
    {
        props.selected(props.color.resumeColorId);
    }

    return (
        <li onClick={onTemplateSelected} className={props.selectedResumeColorID === props.color.resumeColorId ? "active" : ""}><span className={"color-box"} style={{backgroundColor: "rgb(" + props.color.red + "," + props.color.green + "," + props.color.blue + ")"}}></span></li>
    );

}

export default ColorItem;