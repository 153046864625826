import * as React from "react";
import { RouteObject } from "./route"


const OtherSamplePage = React.lazy(() => import("./Demo/Other/SamplePage"));
const OtherFirstPage = React.lazy(() => import("./Demo/Other/FirstPage"));

const LoginPage = React.lazy(() => import("./App/layout/Login/LoginPage"));
const LogoutPage = React.lazy(() => import("./App/layout/Logout/LogoutPage"));
const PreviewPage = React.lazy(() => import("./App/layout/Preview/PreviewPage"));

const AdminDashboardPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/Dashboard/DashboardPage"));
const AdminUsersPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/Admin-Users/AdminUsersPage"));
const ReportsPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/Reports/ReportsPage"));
const TemplatesPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/Templates/TemplatesPage"));
const AutoCompleteCityPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/AutoComplete/Groups/AutoCompleteCityPage"));
const AutoCompleteRegionPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/AutoComplete/Groups/AutoCompleteRegionPage"));
const AutoCompleteCountryPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/AutoComplete/Groups/AutoCompleteCountryPage"));
const AutoCompletePostalCodePage = React.lazy(() => import("./App/layout/AdminLayout/Pages/AutoComplete/Groups/AutoCompletePostalCodePage"));
const AutoCompleteEmployerPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/AutoComplete/Groups/AutoCompleteEmployerPage"));
const AutoCompleteLanguagePage = React.lazy(() => import("./App/layout/AdminLayout/Pages/AutoComplete/Groups/AutoCompleteLanguagePage"));
const AutoCompleteSchoolLocationPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/AutoComplete/Groups/AutoCompleteSchoolLocationPage"));
const AutoCompleteDegreePage = React.lazy(() => import("./App/layout/AdminLayout/Pages/AutoComplete/Groups/AutoCompleteDegreePage"));
const AutoCompleteFieldOfStudyPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/AutoComplete/Groups/AutoCompleteFieldOfStudyPage"));
const SlugsPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/Slugs/SlugsPage"));
const UsersPage = React.lazy(() => import("./App/layout/AdminLayout/Pages/Users/UsersPage"));

const UserDashboardPage = React.lazy(() => import("./App/layout/UserLayout/Pages/Dashboard/DashboardPage"));
const UserMyResumesPage = React.lazy(() => import("./App/layout/UserLayout/Pages/MyResumes/MyResumesPage"));
const UserOnlineResumes = React.lazy(() => import("./App/layout/UserLayout/Pages/OnlineResumes/OnlineResumesPage"));
const UserProfilePage = React.lazy(() => import("./App/layout/UserLayout/Pages/Profile/ProfilePage"));
const UserBillingPage = React.lazy(() => import("./App/layout/UserLayout/Pages/Billing/BillingPage"));
const UserCommunicationPage = React.lazy(() => import("./App/layout/UserLayout/Pages/Communication/CommunicationPage"));
const UserPaymentSuccessPage = React.lazy(() => import("./App/layout/UserLayout/Pages/Payment/Success/PaymentSuccessPage"));
const UserPaymentCanceledPage = React.lazy(() => import("./App/layout/UserLayout/Pages/Payment/Canceled/PaymentCanceledPage"));

const ResumeBuilderChooseTemplatePage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/ChooseTemplate/ChooseTemplatePage"));
const ResumeBuilderHeaderPage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Header/HeaderPage"));
const ResumeBuilderExperiencePage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Experience/ExperiencePage"));
const ResumeBuilderExperienceDetailsPage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Experience/ExperienceDetailsPage"));
const ResumeBuilderExperienceListPage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Experience/ExperienceListPage"));
const ResumeBuilderSignupPage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Signup/SignupPage"));
const ResumeBuilderEducationPage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Education/EducationPage"));
const ResumeBuilderEducationDetailsPage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Education/EducationDetailsPage"));
const ResumeBuilderEducationListPage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Education/EducationListPage"));
const ResumeBuilderSkillsPage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Skills/SkillsPage"));
const ResumeBuilderSummaryPage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Summary/SummaryPage"));
const ResumeBuilderFinalizePage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Finalize/FinalizePage"));
const ResumeBuilderReviewPage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Review/ReviewPage"));
const ResumeBuilderPricingPage = React.lazy(() => import("./App/layout/ResumeLayout/Pages/Pricing/PricingPage"));


const routes: RouteObject[] = [
    { path: "/login", layout: "none", exact: true, name: "Login", component: LoginPage },
    { path: "/logout", layout: "none", exact: true, name: "Logout", component: LogoutPage },
    { path: "/preview", layout: "none", exact: true, name: "Preview", component: PreviewPage },

    { path: "/", layout: "resume", exact: true, name: "Choose Template", component: ResumeBuilderChooseTemplatePage },
    { path: "/choose-template", layout: "resume", exact: true, name: "Choose Template", component: ResumeBuilderChooseTemplatePage },
    { path: "/header", layout: "resume", exact: true, name: "Edit Header", component: ResumeBuilderHeaderPage },
    { path: "/experience", layout: "resume", exact: true, name: "Add Experience", component: ResumeBuilderExperiencePage },
    { path: "/experience/details", layout: "resume", exact: true, name: "Add Experience Details", component: ResumeBuilderExperienceDetailsPage },
    { path: "/experience/list", layout: "resume", exact: true, name: "Review Experiences", component: ResumeBuilderExperienceListPage },
    { path: "/signup", layout: "resume", exact: true, name: "Signup", component: ResumeBuilderSignupPage },
    { path: "/education", layout: "resume", exact: true, name: "Add Education", component: ResumeBuilderEducationPage },
    { path: "/education/details", layout: "resume", exact: true, name: "Add Education Details", component: ResumeBuilderEducationDetailsPage },
    { path: "/education/list", layout: "resume", exact: true, name: "Review Education", component: ResumeBuilderEducationListPage },
    { path: "/skills", layout: "resume", exact: true, name: "Add Skills", component: ResumeBuilderSkillsPage },
    { path: "/summary", layout: "resume", exact: true, name: "Sumary", component: ResumeBuilderSummaryPage },
    { path: "/finalize", layout: "resume", exact: true, name: "Finalize Resume", component: ResumeBuilderFinalizePage },
    { path: "/review", layout: "resume", exact: true, name: "Review Resume", component: ResumeBuilderReviewPage },
    { path: "/pricing", layout: "resume", exact: true, name: "Pricing", component: ResumeBuilderPricingPage },
        
    { path: "/admin", layout: "admin", exact: true, name: "Dashboard Page", component: AdminDashboardPage },
    { path: "/admin/users-list", layout: "admin", exact: true, name: "Admin Users Page", component: AdminUsersPage },

    { path: "/admin/users-list/user", layout: "admin", exact: true, name: "Sample Page", component: OtherSamplePage },
    { path: "/admin/subscriptions", layout: "admin", exact: true, name: "Sample Page", component: OtherSamplePage },
    { path: "/admin/communication", layout: "admin", exact: true, name: "Sample Page", component: OtherSamplePage },

    { path: "/admin/reports", layout: "admin", exact: true, name: "Reports", component: ReportsPage },
    { path: "/admin/templates", layout: "admin", exact: true, name: "Resume Templates", component: TemplatesPage },

    { path: "/admin/autocomplete", layout: "admin", exact: true, name: "Sample Page", component: OtherSamplePage },

    { path: "/admin/autocomplete/city", layout: "admin", exact: true, name: "Autocomplete Cities Page", component: AutoCompleteCityPage },
    { path: "/admin/autocomplete/region", layout: "admin", exact: true, name: "Autocomplete States Page", component: AutoCompleteRegionPage },
    { path: "/admin/autocomplete/country", layout: "admin", exact: true, name: "Autocomplete Countries Page", component: AutoCompleteCountryPage },
    { path: "/admin/autocomplete/postal-code", layout: "admin", exact: true, name: "Autocomplete Zip Codes Page", component: AutoCompletePostalCodePage },
    { path: "/admin/autocomplete/employer", layout: "admin", exact: true, name: "Autocomplete Employers Page", component: AutoCompleteEmployerPage },
    { path: "/admin/autocomplete/language", layout: "admin", exact: true, name: "Autocomplete Languages Page", component: AutoCompleteLanguagePage },
    { path: "/admin/autocomplete/school-location", layout: "admin", exact: true, name: "Autocomplete Schools / Locations Page", component: AutoCompleteSchoolLocationPage },
    { path: "/admin/autocomplete/degree", layout: "admin", exact: true, name: "Autocomplete Degrees Page", component: AutoCompleteDegreePage },
    { path: "/admin/autocomplete/field-of-study", layout: "admin", exact: true, name: "Autocomplete Fields of Study Page", component: AutoCompleteFieldOfStudyPage },

    { path: "/admin/affiliates", layout: "admin", exact: true, name: "Sample Page", component: OtherSamplePage },

    { path: "/admin/panel/slugs", layout: "admin", exact: true, name: "Slugs Page", component: SlugsPage },
    { path: "/admin/panel/users", layout: "admin", exact: true, name: "Users Page", component: UsersPage },
    
    { path: "/admin/panel/logout", layout: "admin", exact: true, name: "Sample Page", component: OtherSamplePage },

    { path: "/account", layout: "user", exact: true, name: "Dashboard Page", component: UserDashboardPage },
    { path: "/account/my-resumes", layout: "user", exact: true, name: "My Resumes", component: UserMyResumesPage },
    { path: "/account/online-resumes", layout: "user", exact: true, name: "Online Resumes", component: UserOnlineResumes },
    { path: "/account/account/profile", layout: "user", exact: true, name: "Online Resumes", component: UserProfilePage },
    { path: "/account/account/billing", layout: "user", exact: true, name: "Billing", component: UserBillingPage },
    { path: "/account/account/communications", layout: "user", exact: true, name: "Communication", component: UserCommunicationPage },
    { path: "/account/payment/success", layout: "user", exact: true, name: "Payment Success", component: UserPaymentSuccessPage },
    { path: "/account/payment/canceled", layout: "user", exact: true, name: "Payment Canceled", component: UserPaymentCanceledPage },

];

export default routes;
