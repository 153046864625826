import * as React from "react";
import { Component, ErrorInfo, FunctionComponent, useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { store, StoreState } from "./shared/redux/store";
import APIClientFactory from "./shared/utils/APIClientFactory";
import { LoginResponse } from "./shared/utils/DataAccess";
import LocalStorage, { LocalStorageSetting } from "./shared/utils/LocalStorage";


export const ApiErrorActionType: string = "apiPromiseError";

export type ErrorBoundaryState =
{
    promiseError: Error | null;
}

const initialState = { promiseError: null };


export const errorBoundaryReducer = (state: any, action: any) => 
{
    switch (action.type) 
    {
        case ApiErrorActionType:
            return { promiseError: action.payload };
        default:
            break;
    }

    return {...initialState};
};



const ErrorBoundary = (props: any) =>
{
    const [promiseError, setPromiseError] = useState<any | null>(null);

    useSelector((state: StoreState) => 
    {
        if (promiseError !== state.errorBoundary.promiseError)
        {
            setPromiseError(state.errorBoundary.promiseError);
        }
        return state.errorBoundary;
    })

    let history = useHistory();


    useEffect(() =>
    {
        if (promiseError === null)
        {
            return;
        }

        if (
                promiseError === undefined || 
                promiseError.status === 401 || 
                promiseError.message === "Unexpected token < in JSON at position 0" ||
                promiseError.message === "Unexpected token U in JSON at position 0" // not logged in, can't access this record
            )
        {
            store.dispatch({ type: "AUTHENTICATION", payload: {
                isAuthenticated: false,
                isLoggedIn: false,
                isCookie: false,
                isOAuth: false,
                isAdmin: false,
                isCustomer: false,
                returnUrl: window.location.pathname
            } });

            history.push("/login");
        }
        else if (promiseError.detail !== undefined && promiseError.detail.length > 0)
        {
            alert(promiseError.detail);
        }
        else if (promiseError.status === 403)
        {
            alert("You do not have access to this resource");
        }
        else if (promiseError.status === 404)
        {
            alert("Not Found");
        }
        
        else if (promiseError.status === 400)
        {
            alert("Bad Request");
        }
        else if (promiseError.status === 415)
        {
            alert("Unsupported Media Type");
        }
        else if (promiseError.status === 500)
        {
            alert("Internal Server Error");
        }
        else if (promiseError.status !== 200 && promiseError.status !== 204)
        {
            alert("An unexpected server error occurred.\r\n" + promiseError.message);
        }
    }, [promiseError]);


    return (
        props.children
    );

}

export default ErrorBoundary;