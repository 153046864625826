import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../../../hooks/useWindowSize";
import NavLeft from "./NavLeft";
import NavRight from "./NavRight";

import LINKS from "../../../../store/constant";
import * as actionTypes from "../../../../store/actions";
import logo from "../../../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { StoreState } from "../../../../shared/redux/store";


const NavigationBar = () => 
{
    const dispatch = useDispatch();
    const { windowWidth } = useWindowSize();
    const rtlLayout = useSelector((state: StoreState) => state.able.rtlLayout);
    const headerBackColor = useSelector((state: StoreState) => state.able.headerBackColor);
    const headerFixedLayout = useSelector((state: StoreState) => state.able.headerFixedLayout);
    const collapseMenu = useSelector((state: StoreState) => state.able.collapseMenu);
    const layout = useSelector((state: StoreState) => state.able.layout);
    const subLayout = useSelector((state: StoreState) => state.able.subLayout);
    const onToggleNavigation = () => dispatch({ type: actionTypes.COLLAPSE_MENU });
    const [rightToggle, setRightToggle] = useState(false);
    let headerClass = ["navbar", "pcoded-header", "navbar-expand-lg", "header-blue"];
    document.body.classList.remove("background-blue");
    document.body.classList.remove("background-red");
    document.body.classList.remove("background-purple");
    document.body.classList.remove("background-info");
    document.body.classList.remove("background-green");
    document.body.classList.remove("background-dark");
    document.body.classList.remove("background-grd-blue");
    document.body.classList.remove("background-grd-red");
    document.body.classList.remove("background-grd-purple");
    document.body.classList.remove("background-grd-info");
    document.body.classList.remove("background-grd-green");
    document.body.classList.remove("background-grd-dark");
    document.body.classList.remove("background-img-1");
    document.body.classList.remove("background-img-2");
    document.body.classList.remove("background-img-3");
    document.body.classList.remove("background-img-4");
    document.body.classList.remove("background-img-5");
    document.body.classList.remove("background-img-6");
    document.body.classList.add(headerBackColor);
    if (headerFixedLayout) 
{
        headerClass = [...headerClass, "headerpos-fixed"];
    }
    let toggleClass = ["mobile-menu"];
    if (collapseMenu) 
{
        toggleClass = [...toggleClass, "on"];
    }
    let mainLogo = logo;
    let navHtml: React.ReactNode;
    if (!rightToggle && windowWidth < 992) 
{
        navHtml = "";
    }
 else 
{
        navHtml = (
            <div className="collapse navbar-collapse d-flex">
                <NavLeft />
                <NavRight rtlLayout={rtlLayout} />
            </div>
        );
    }
    let navBar = (
        <>
            <div className="m-header">
                <a className={toggleClass.join(" ")} id="mobile-collapse1" href="#" onClick={onToggleNavigation}>
                    <span />
                </a>
                <Link to={LINKS.USER_LINK} className="b-brand">
                    <img id="main-logo" src={mainLogo} alt="" className="logo" />
                </Link>
                <a className="mob-toggler" href={LINKS.USER_LINK} onClick={() => setRightToggle(!rightToggle)}>
                    <i className="feather icon-more-vertical" />
                </a>
            </div>
            {navHtml}
        </>
    );
    if (layout === "horizontal" && subLayout === "horizontal-2") 
{
        navBar = <div className="container">{navBar}</div>;
    }
    return <header className={headerClass.join(" ")}>{navBar}</header>;
};
export default NavigationBar;
