export interface RouteObject {
    path: string;
    exact?: boolean;
    layout: string,
    name?: string;
    component: any;
}
const route: RouteObject[] = [

];
export default route;
