/* eslint-disable camelcase */
import * as Oidc from "oidc-client";
import * as Config from "../../config";

const config: Oidc.UserManagerSettings = {
    authority: "https://accounts.google.com",
    client_id: "466224524773-59eh65ogc5idkftpnkq2o1e27299u8cr.apps.googleusercontent.com",
    redirect_uri: Config.default.apiPath + "/redirect",
    response_type: "id_token token",
    scope: "openid profile email",
    post_logout_redirect_uri: Config.default.apiPath + "/logout",
    filterProtocolClaims: true,
    loadUserInfo: true
}

const userManager = new Oidc.UserManager(config);

export default userManager