import * as React from "react";

const NavLeft = () => 
{
    return (
        <ul className="navbar-nav mr-auto">
            <li className="nav-item">
            </li>
        </ul>
    );
};

export default NavLeft;
