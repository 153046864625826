import { store } from "./store";

export const initialState = {
    isAuthenticated: false as boolean,
    isLoggedIn: false as boolean,
    isCookie: false as boolean,
    isOAuth: false as boolean,
    isAdmin: false as boolean,
    isCustomer: false as boolean,
    pendingId: "" as string,
    returnUrl: "" as string,
    personName: "" as string,
};

export type ACTIONTYPE = {
    type: "AUTHENTICATION";
    payload: {
        isAuthenticated: boolean;
        isLoggedIn: boolean;
        isCookie: boolean;
        isOAuth: boolean; 
        isAdmin: boolean;
        isCustomer: boolean;
        pendingId: string,
        returnUrl: string;
        personName: string;
    }
}

const authenticationReducer = (state = { ...initialState }, action: ACTIONTYPE): typeof initialState => 
{
    switch (action.type) 
    {
        case "AUTHENTICATION":
            const returnUrl = (action.payload.returnUrl === "/login") ? state.returnUrl : action.payload.returnUrl;
            if (returnUrl !== undefined)
            {
                window.sessionStorage.setItem("returnUrl", returnUrl);
            }

            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                isLoggedIn: action.payload.isLoggedIn,
                isCookie: action.payload.isCookie,
                isOAuth: action.payload.isOAuth,
                isAdmin: action.payload.isAdmin,
                isCustomer: action.payload.isCustomer,
                returnUrl: returnUrl,
                personName: action.payload.personName
            };
        default:
            return state;
    }
};

export default authenticationReducer;
