import * as React from "react";
import { lazy, Suspense } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import "../../node_modules/font-awesome/scss/font-awesome.scss";
import Loader from "./layout/Loader";
import ScrollToTop from "./layout/ScrollToTop";
import routesOnePage from "../route";
import routes from "../routes";
import FrontEndLayout from "./layout/FrontEndLayout";
import UserLayout from "./layout/UserLayout";
import ResumeLayout from "./layout/ResumeLayout";
import OAuthResponsePage from "./layout/OAuthResponse/OAuthResponsePage";
import SlugLayout from "./layout/SlugLayout";
import ContactPage from "./layout/SlugLayout/Pages/ContactPage";


const AdminLayout = lazy(() => import("./layout/AdminLayout"));

const App = () => 
{
    const location = useLocation();

    return (
        <>
            <ScrollToTop>
                <Suspense fallback={<Loader />}>
                    <Route path={routesOnePage.map((x) => x.path)}>
                        <Switch location={location} key={location.pathname}>
                            {routesOnePage.map((route, index) => 
                            {
                                return route.component ? (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        render={(props: any) => <route.component {...props} />}
                                    />
                                ) : null;
                            })}
                        </Switch>
                    </Route>
                    <Switch>
                        <Route exact={false} path={"/redirect"}>
                            <OAuthResponsePage />
                        </Route>
                        <Route exact={true} path={routes.filter((x) => x.layout === "admin").map((x) => x.path)}>
                            <AdminLayout />
                        </Route>
                        <Route exact={true} path={routes.filter((x) => x.layout === "user").map((x) => x.path)}>
                            <UserLayout />
                        </Route>
                        <Route exact={true} path={routes.filter((x) => x.layout === "resume").map((x) => x.path)}>
                            <ResumeLayout />
                        </Route>
                        <Route path={routes.filter((x) => x.layout !== "admin" && x.layout !== "user" && x.layout !== "resume").map((x) => x.path)}>
                            <FrontEndLayout />
                        </Route>
                        <Route path="*/contact">
                            <ContactPage />
                        </Route>
                        <Route>
                            <SlugLayout />
                        </Route>
                    </Switch>
                </Suspense>
            </ScrollToTop>
            <div className="backdrop" />
        </>
    );
};

export default App;
