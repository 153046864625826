interface Badge {
    title: string;
    type: string;
}
export interface MenuItemType {
    id: string;
    title: string;
    type: string;
    icon?: string;
    children?: MenuItemType[];
    breadcrumbs?: boolean;
    url?: string;
    badge?: Badge;
    target?: boolean;
    classes?: string;
    external?: boolean;
}
const chartData: { items: MenuItemType[] } = {
    items: [
        {
            id: "navigation",
            title: "User Account",
            type: "group",
            icon: "icon-support",
            children: [
                {
                    id: "user",
                    title: "Dashboard",
                    type: "collapse",
                    url: "/account",
                    icon: "feather icon-home",
                    children: [
                        {
                            id: "account/home",
                            title: "Home",
                            type: "item",
                            url: "/account",
                        },
                        {
                            id: "account/my-resumes",
                            title: "My Resumes",
                            type: "item",
                            url: "/account/my-resumes",
                        },
                        {
                            id: "account/online-resumes",
                            title: "Online Resumes",
                            type: "item",
                            url: "/account/online-resumes",
                        },
                        {
                            id: "account/account",
                            title: "Account",
                            type: "collapse",
                            url: "/account/account",
                            children: [
                                {
                                    id: "account/account/profile",
                                    title: "Edit Profile",
                                    type: "item",
                                    url: "/account/account/profile",
                                },
                                {
                                    id: "account/account/billing",
                                    title: "Billing",
                                    type: "item",
                                    url: "/account/account/billing",
                                },
                                {
                                    id: "account/account/communications",
                                    title: "Communications",
                                    type: "item",
                                    url: "/account/account/communications",
                                }
                            ]
                        },
                    ]
                },
            ]
        },

        {
            id: "account/support",
            title: "Support",
            type: "item",
            url: "/account/support",
            icon: "feather icon-message-square",
        },
        {
            id: "account/panel/logout",
            title: "Logout",
            type: "item",
            url: "/logout",
            icon: "feather icon-log-out",
        },

        // {
        //     id: "panels",
        //     title: "Other Panels",
        //     type: "group",
        //     icon: "icon-support",
        //     children: [
        //         {
        //             id: "front-end/panel",
        //             title: "Front End",
        //             type: "item",
        //             url: "/",
        //             icon: "feather icon-sidebar"
        //         },
        //         {
        //             id: "admin/panel",
        //             title: "Admin Panel",
        //             type: "item",
        //             url: "/admin",
        //             icon: "feather icon-sidebar"
        //         }
        //     ]
        // },

    ]
};
export default chartData;
