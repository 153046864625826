import { User } from "oidc-client";
import { store } from "../redux/store";
import userManager from "./UserManager";


class BaseClient 
{


    protected transformOptions(options: RequestInit) 
    {
        const state = store.getState() as any;
        let token = (state.userReducer && state.userReducer.user && state.userReducer.user.access_token) || null;

        if (token === null)
        {
            const oauthUser = window.sessionStorage.getItem("user");
            if (oauthUser !== null)
            {
                const user: User = JSON.parse(oauthUser);
                token = user.id_token;
            }
        }

        if (token !== null)
        {
            options.headers = { 
                ...options.headers, 
                ["Authorization"]: `Bearer ${token}` 
            };
        }

        options.credentials = "include";

        return Promise.resolve(options);
    }


}


export default BaseClient;