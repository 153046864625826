import * as React from "react";
import { useEffect, Suspense, useReducer, useState } from "react";
import { Route, Switch, Redirect, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TagManager from "react-gtm-module"
import Loader from "../Loader";
import routes from "../../../routes";
import useWindowSize from "../../../hooks/useWindowSize";
import logo from "../../../assets/images/logo-dark.svg";

import * as actionTypes from "../../../store/actions";
import { store, StoreState } from "../../../shared/redux/store";
import { APIClientFactory } from "../../../shared/utils/APIClientFactory";
import { ApiErrorActionType } from "../../../ErrorBoundary";
import { CustomerResumeAddRequest, CustomerResumeEducationAddOrUpdateRequest, CustomerResumeEnumerateResponse, CustomerResumeExperiencesAddOrUpdateRequest, CustomerResumeInformation, CustomerResumeUpdateRequest, LoginResponse, ResumeColorInformation, ResumeTemplates } from "../../../shared/utils/DataAccess";
import ColorItem from "./ColorItem";
import LocalStorage, { LocalStorageSetting } from "../../../shared/utils/LocalStorage";
import { isJson } from "../../../shared/utils/Utilities";
import { RouteObject } from "../../../route";


export interface ResumeLayoutConfig
{
    currentPage: number,
    nextPage: string,
    pageHeader: string,
    pageFooter: string,
    customFooter: ((() => JSX.Element) | null)
}


const ResumeLayout = () => 
{
    const history = useHistory();
    const dispatch = useDispatch();
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    
    const isAuthenticated = useSelector((state: StoreState) => state.authentication.isAuthenticated);

    const defaultPath = useSelector((state: StoreState) => state.able.defaultPath);
    const collapseMenu = useSelector((state: StoreState) => state.able.collapseMenu);
    const layout = useSelector((state: StoreState) => state.able.layout);
    const subLayout = useSelector((state: StoreState) => state.able.subLayout);
    
    const { windowWidth } = useWindowSize();
    const [pageAuthenticated, setPageAuthenticated] = useState<boolean>(false);
    const [colors, setColors] = useState<ResumeColorInformation[]>([]);
    const [config, setConfig] = useState<ResumeLayoutConfig>({
        currentPage: 0,
        nextPage: "header",
        pageHeader: "no-header",
        pageFooter: "no-footer",
        customFooter: null 
    });
    const [seletedResumeColorID, setSelectedResumeColorID] = useState(-1);
    const [resume, setResume] = useState<CustomerResumeInformation | null>(null);
    const [updateResumeRequest, setUpdateResumeRequest] = useState<CustomerResumeUpdateRequest | null>(null);
    const [updateResumeRequestNow, setUpdateResumeRequestNow] = useState<CustomerResumeUpdateRequest | null>(null);
    const [updateExperienceRequest, setUpdateExperienceRequest] = useState<CustomerResumeExperiencesAddOrUpdateRequest | null>(null);
    const [updateEducationRequest, setUpdateEducationRequest] = useState<CustomerResumeEducationAddOrUpdateRequest | null>(null);
   
    
    let mainClass = ["pcoded-wrapper"];

    useEffect(() => 
    {
        const tagManagerArgs = 
        {
            gtmId: "G-7L1LL4NFE1"
        }
    
        TagManager.initialize(tagManagerArgs)
    
        if (windowWidth > 992 && windowWidth <= 1024 && layout !== "horizontal") 
        {
            dispatch({ type: actionTypes.COLLAPSE_MENU });
        }
        
        if (isAuthenticated)
        {
            setPageAuthenticated(true);
            loadResume();
        }
        else
        {
            authenticate();
        }

        enumerateColors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => 
    {
        if (resume === null || resume.resumeColor === null)
        {
            return;
        }

        setSelectedResumeColorID(resume.resumeColor.resumeColorId);
    }, [resume]);


    useEffect(() => 
    {
        if (updateResumeRequestNow === null)
        {
            return;
        }

        APIClientFactory.getInstance()
            .updateResumeById(resume?.customerResumeId!, updateResumeRequestNow)
            .then(() =>
            {
                setResume(CustomerResumeInformation.fromJS({
                    ...resume,
                    resumeTemplate: { ...updateResumeRequestNow.resumeTemplate },
                    resumeColor: { ...updateResumeRequestNow.resumeColor },
                    name: updateResumeRequestNow.name,
                    active: updateResumeRequestNow.active,
                    personName: { ...updateResumeRequestNow.personName },
                    resumeAddress: { ...updateResumeRequestNow.resumeAddress },
                    phoneNumber: { ...updateResumeRequestNow.phoneNumber },
                    emailAddress: { ...updateResumeRequestNow.emailAddress },
                    summary: updateResumeRequestNow.summary,
                    fontStyle: updateResumeRequestNow.fontStyle,
                    fontSize: updateResumeRequestNow.fontSize,
                    headingSize: updateResumeRequestNow.headingSize,
                    sectionSpacing: updateResumeRequestNow.sectionSpacing,
                    paragraphSpacing: updateResumeRequestNow.paragraphSpacing,
                    paragraphIndent: updateResumeRequestNow.paragraphIndent,
                    lineSpacing: updateResumeRequestNow.lineSpacing,
                    slug: updateResumeRequestNow.slug,
                    useAccomplishments : updateResumeRequestNow.useAccomplishments,
                    useActivities : updateResumeRequestNow.useActivities,
                    useAdditionalInformation : updateResumeRequestNow.useAdditionalInformation,
                    useCertifications : updateResumeRequestNow.useCertifications,
                    useInterests : updateResumeRequestNow.useInterests,
                    useLanguages : updateResumeRequestNow.useLanguages,
                    useSoftware : updateResumeRequestNow.useSoftware,
                    useWebsites : updateResumeRequestNow.useWebsites,
                }));

                setUpdateResumeRequestNow(null);
            })
            .catch((e: any) =>
            {
                store.dispatch({ type: ApiErrorActionType, payload: e });
            });

    }, [updateResumeRequestNow]);


    const updateResumeNow = (request: CustomerResumeUpdateRequest) =>
    {
        setUpdateResumeRequestNow(request);
    }


    const updateResume = (request: CustomerResumeUpdateRequest) =>
    {
        setUpdateResumeRequest(request);
    }


    const updateExperience = (request: CustomerResumeExperiencesAddOrUpdateRequest) =>
    {
        setUpdateExperienceRequest(request);
    }


    const updateEducation = (request: CustomerResumeEducationAddOrUpdateRequest) =>
    {
        setUpdateEducationRequest(request);
    }

    const resumeUpdated = (update: CustomerResumeInformation) =>
    {
        setResume(update);
        LocalStorage.set(LocalStorageSetting.CURRENT_RESUME, JSON.stringify(update));
    }


    const authenticate = () =>
    {
        APIClientFactory.getInstance()
            .whoAmI()
            .then((response: LoginResponse) =>
            {
                dispatch({ 
                    type: "AUTHENTICATION", 
                    payload: {
                        isAuthenticated: true, 
                        isLoggedIn: true,
                        isCookie: true, 
                        isOAuth: false, 
                        isAdmin: false, 
                        isCustomer: true,
                        personName: response.personName,
                        pendingId: response.pendingId
                    }
                });

                LocalStorage.removeItem(LocalStorageSetting.PENDING_CUSTOMER_ID);
                setPageAuthenticated(true);
                loadResume();                
            })
            .catch((reason: any) =>
            {
                //not logged in, get temporary id
                requestTemporaryCustomer();
            });
    }
    
    
    const requestTemporaryCustomer = () =>
    {
        const pendingId: string | null = LocalStorage.get(LocalStorageSetting.PENDING_CUSTOMER_ID);

        APIClientFactory.getInstance()
            .getTemporaryResumeCustomer(pendingId)
            .then((response: LoginResponse) =>
            {
                dispatch({ 
                    type: "AUTHENTICATION", 
                    payload: {
                        isAuthenticated: true, 
                        isLoggedIn: false,
                        isCookie: true, 
                        isOAuth: false, 
                        isAdmin: false, 
                        isCustomer: true,
                        personName: response.personName,
                        pendingId: response.pendingId
                    }
                });

                LocalStorage.set(LocalStorageSetting.PENDING_CUSTOMER_ID, response.pendingId!);
                setPageAuthenticated(true);
                loadResume();                
            })
            .catch((e: any) =>
            {
                store.dispatch({ type: ApiErrorActionType, payload: e });
            });
    }

    const loadResume = () =>
    {
        const json: string | null = LocalStorage.get(LocalStorageSetting.CURRENT_RESUME);
        if (json !== null)
        {   //last edited resume, set for editing from local cache....then try to load from server
            if (!isJson(json))
            {
                loadResumeById(json);
            }
            else
            {
                const resume: CustomerResumeInformation = CustomerResumeInformation.fromJS(JSON.parse(json))

                setResume(resume);
                loadResumeById(resume.customerResumeId);
            }
        }
        else
        {   //no last resume, try to find one
            loadFirstResume();
        }
    }

    const loadResumeById = (id: string) =>
    {
        APIClientFactory.getInstance()
            .getCustomerResumeById(id)
            .then((result: CustomerResumeInformation) =>
            {
                LocalStorage.set(LocalStorageSetting.CURRENT_RESUME, JSON.stringify(result));
                setResume(result);
            })
            .catch((e: any) =>
            {
                store.dispatch({ type: ApiErrorActionType, payload: e });
            });
    }

    const loadFirstResume = () =>
    {
        APIClientFactory.getInstance()
            .enumerateResumes(1, 0, null, true)
            .then((response: CustomerResumeEnumerateResponse) =>
            {
                if (response.totalRecods > 0)
                {   // found one to edit
                    LocalStorage.set(LocalStorageSetting.CURRENT_RESUME, JSON.stringify(response.resumes[0]));
                    setResume(response.resumes[0]);
                }
                else
                {   // no resume, we need to create a new one
                    createNewResume();
                }
            })
            .catch((e: any) =>
            {
                store.dispatch({ type: ApiErrorActionType, payload: e });
            });

    }

    const createNewResume = () =>
    {
        const request: CustomerResumeAddRequest = CustomerResumeAddRequest.fromJS({
            name: "New Resume"
        });

        APIClientFactory.getInstance()
            .createCustomerResume(request)
            .then((id: string) =>
            {
                loadResumeById(id);
            })
            .catch((e: any) =>
            {
                store.dispatch({ type: ApiErrorActionType, payload: e });
            });
    }

    if (layout === "horizontal" && subLayout === "horizontal-2") 
    {
        mainClass = [...mainClass, "container"];
    }

    const mobileOutClickHandler = () => 
    {
        if (windowWidth < 992 && collapseMenu) 
        {
            dispatch({ type: actionTypes.COLLAPSE_MENU });
        }
    };

    const changeConfig = (newConfig: ResumeLayoutConfig) =>
    {
        setConfig(newConfig);
    }

    const reRenderRequired = () =>
    {
        forceUpdate();
    }

    const enumerateColors = () =>
    {
        APIClientFactory.getInstance()
            .enumerateColors()
            .then((response: ResumeColorInformation[]) =>
            {
                setColors(response);
                if (response !== null && response.length > 0)
                {
                    setSelectedResumeColorID(response[0].resumeColorId);
                }
                reRenderRequired();
            })
            .catch((e: any) =>
            {
                store.dispatch({ type: ApiErrorActionType, payload: e });
            });
    }

    const GetProgress = (page: number) : string =>
    {
        switch (page)
        {
            case 0:
                return "10";
                case 1:
                    return "25";
                case 2:
                    return "40";
                case 3:
                    return "70";
                case 4:
                    return "85";
                default:
                    return "100";
        }
    }

    const RenderHeader = () =>
    {
        if (config.pageHeader === "no-header")
        {
            return <></>;
        }
        else if (config.pageHeader === "progress")
        {
            return <div className="progress-block">
                    <div className="mobile-progress-title">{GetProgress(config.currentPage)}%</div>
                    <a className={"step" + (config.currentPage === 0 ?  " active" : "") + (config.currentPage > 0 ?  " visited" : "")} href="javascript:void(0)">Header</a>
                    <a className={"step" + (config.currentPage === 1 ?  " active" : "") + (config.currentPage > 1 ?  " visited" : "")} href="javascript:void(0)">Experience</a>
                    <a className={"step" + (config.currentPage === 2 ?  " active" : "") + (config.currentPage > 2 ?  " visited" : "")} href="javascript:void(0)">Education</a>
                    <a className={"step" + (config.currentPage === 3 ?  " active" : "") + (config.currentPage > 3 ?  " visited" : "")} href="javascript:void(0)">Skills</a>
                    <a className={"step" + (config.currentPage === 4 ?  " active" : "") + (config.currentPage > 4 ?  " visited" : "")} href="javascript:void(0)">Summary</a>
                    <a className={"step" + (config.currentPage === 5 ?  " active" : "") + (config.currentPage > 5 ?  " visited" : "")} href="javascript:void(0)">Finalize</a>
                </div>;               
        }
        else
        {
            return <>
                    <a className="blue-btn small" href="#"><i className="download-icon"></i> Download</a>
                </>;
        }
    }

    const onColorChanged = (resumeColorID: any) =>
    {
        setSelectedResumeColorID(resumeColorID);
        
        APIClientFactory.getInstance()
            .updateResumeColorStyleById(resume!.customerResumeId, resumeColorID)
            .then(() =>
            {
                var resumeColor: ResumeColorInformation | null = null;

                colors.forEach((item: ResumeColorInformation) =>
                {
                    if (item.resumeColorId === resumeColorID)
                    {
                        resumeColor = item;
                    }
                });

                resumeUpdated(CustomerResumeInformation.fromJS({
                    ...resume,
                    resumeColor: resumeColor
                }));
            })
            .catch((e: any) =>
            {
                store.dispatch({ type: ApiErrorActionType, payload: e });
            });
    }

    const onSaveNow = () =>
    {
        onNextClick(true);
    }

    const onNextClick = (e: any) =>
    {
        if (!e || (updateResumeRequest === null && updateExperienceRequest === null && updateEducationRequest === null))
        {
            return;
        }

        if (e.preventDefault)
        {
            e.preventDefault();
        }
        
        if (updateResumeRequest !== null)
        {
            APIClientFactory.getInstance()
                .updateResumeById(resume?.customerResumeId!, updateResumeRequest)
                .then(() =>
                {
                    setResume(CustomerResumeInformation.fromJS({
                        ...resume,
                        resumeTemplate: { ...updateResumeRequest.resumeTemplate },
                        resumeColor: { ...updateResumeRequest.resumeColor },
                        name: updateResumeRequest.name,
                        active: updateResumeRequest.active,
                        personName: { ...updateResumeRequest.personName },
                        resumeAddress: { ...updateResumeRequest.resumeAddress },
                        phoneNumber: { ...updateResumeRequest.phoneNumber },
                        emailAddress: { ...updateResumeRequest.emailAddress },
                        summary: updateResumeRequest.summary,
                        fontStyle: updateResumeRequest.fontStyle,
                        fontSize: updateResumeRequest.fontSize,
                        headingSize: updateResumeRequest.headingSize,
                        sectionSpacing: updateResumeRequest.sectionSpacing,
                        paragraphSpacing: updateResumeRequest.paragraphSpacing,
                        paragraphIndent: updateResumeRequest.paragraphIndent,
                        lineSpacing: updateResumeRequest.lineSpacing,
                        slug: updateResumeRequest.slug
                    }));

                    setUpdateResumeRequest(null);
                    
                    if (e.target)
                    {
                        window.setTimeout(() => 
                        {
                            e.target.click();
                        }, 250);
                    }
                })
                .catch((e: any) =>
                {
                    store.dispatch({ type: ApiErrorActionType, payload: e });
                });

            return;
        }
       
        if (updateExperienceRequest !== null)
        {
            const experienceId: number = parseInt(LocalStorage.get(LocalStorageSetting.CURRENT_EXPERIENCE) ?? "0");

            APIClientFactory.getInstance()
                .updateResumeExperienceById(resume?.customerResumeId!, experienceId, updateExperienceRequest)
                .then(() =>
                {
                    setUpdateExperienceRequest(null);
                    
                    window.setTimeout(() => 
                    {
                        e.target.click();
                    }, 250);
                })
                .catch((e: any) =>
                {
                    store.dispatch({ type: ApiErrorActionType, payload: e });
                });

            return;
        }
       
        if (updateEducationRequest !== null)
        {
            const educationId: number = parseInt(LocalStorage.get(LocalStorageSetting.CURRENT_EDUCATION) ?? "0");
            updateEducationRequest.description = updateEducationRequest.description ?? "";
            updateEducationRequest.schoolAddress.line1 = updateEducationRequest.schoolAddress.line1 ?? "";
            updateEducationRequest.schoolAddress.line2 = updateEducationRequest.schoolAddress.line2 ?? "";
            updateEducationRequest.schoolAddress.country = updateEducationRequest.schoolAddress.country ?? "";
            updateEducationRequest.schoolAddress.postalCode = updateEducationRequest.schoolAddress.postalCode ?? "";
 
            APIClientFactory.getInstance()
                .updateCustomerResumeEducationById(resume?.customerResumeId!, educationId, updateEducationRequest)
                .then(() =>
                {
                    setUpdateEducationRequest(null);
                    
                    window.setTimeout(() => 
                    {
                        e.target.click();
                    }, 250);
                })
                .catch((e: any) =>
                {
                    store.dispatch({ type: ApiErrorActionType, payload: e });
                });

            return;
        }
    }

    const RenderFooter = () =>
    {
        if (config.pageFooter === "no-footer")
        {
            return <></>;
        }
        else if (config.pageFooter === "choose-template")
        {
            return <>
                    <div className="color-wrapper">
                        <div className="selected-color">
                            <span className="color-box" style={{backgroundColor: "#373D48"}}></span>
                        </div>
                        <ul className="color-list">
                            {colors.map((value: ResumeColorInformation, i: number) =>
                                {
                                    return (
                                        <ColorItem
                                            key={value.resumeColorId}
                                            selectedResumeColorID={seletedResumeColorID}
                                            color={value}
                                            selected={onColorChanged}
                                        ></ColorItem>
                                    );
                                })
                            }
                        </ul>
                    </div>
                    {/* <a className="skip" href="#">Skip For Now</a> */}
                    <Link className="blue-btn" to={config.nextPage}>Choose This Template</Link>
                </>;
        }
        else if (config.pageFooter === "progress")
        {
            return <>
                    <div className="left-block">
                        <button className="bordered-btn" onClick={history.goBack}>Back</button>
                    </div>
                    <div className="right-block">
                        <Link className="blue-btn" to={config.nextPage} onClick={onNextClick}>Continue</Link>
                    </div>
                </>;
        }
        else if (config.customFooter !== null)
        {
            return config.customFooter();
        }
        else
        {
            return <>
                    <div className="mobile-action text-center btn-weapper">
                        <a className="bordered-btn sidebar-btn" href="javascript:void(0)">Edit Resume</a>
                        <a className="blue-btn filter-btn" href="javascript:void(0)">Filter</a>
                    </div>
                </>;
        }
    }

    const currentRoute: RouteObject | undefined = routes.find(r =>
    {
        return r.path === document.location.pathname;
    });

    if (currentRoute === undefined)
    {
        document.title = "Fast Resume";
    }
    else
    {
        document.title = currentRoute.name + " | Fast Resume";
    }

    return (
        <>
            <style>{"body { background-color: #ffffff; }"}</style>
            <div className="resume-builder">
                <header className={"site-header" + (config.pageHeader === "progress" ? " with-progress" : "")}>
                    <div className="container">
                        <div className="logo">
                            <a href="#"><img id="main-logo" src={logo} alt="" className="logo" /></a>
                        </div>
                        {RenderHeader()}
                    </div>
                </header>
                <section className="s-pt-half bottom-sticky-opt">
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            {routes.map((route, index) => 
                            {
                                let layoutCallbacks = {
                                    resume: resume,
                                    pageAuthenticated: pageAuthenticated,
                                    changeAll: changeConfig,
                                    resumeUpdated: resumeUpdated,
                                    reRenderRequired: reRenderRequired,
                                    updateResume: updateResume,
                                    updateResumeNow: updateResumeNow,
                                    updateExperience: updateExperience,
                                    updateEducation: updateEducation,
                                    onSaveNow: onSaveNow
                                };

                                return route.component ? (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        render={(props) => <route.component {...props} {...layoutCallbacks} />}
                                    />
                                ) : null;
                            })}
                            <Redirect from="/" to={defaultPath} />
                        </Switch>
                    </Suspense>
                    <div className="resume-sticky-opt">
                        <div className="container">
                            {RenderFooter()}
                        </div>
                    </div>
                </section>            
            </div>
        </>
    );
};
export default ResumeLayout;
