import { connectRouter, routerMiddleware, RouterState } from "connected-react-router"
import { applyMiddleware, combineReducers, createStore, Reducer } from "redux";
import { createBrowserHistory, History } from "history"
import { reducer as userReducerFromPackge, UserState } from "redux-oidc"
import ableReducer, { initialState as AbleState } from "../../store/ableReducer";
import demoReducer, { initialState as DemoState } from "../../store/demoReducer";
import authenticationReducer, { initialState as AuthenticationState } from "./authenticationReducer";
import { ErrorBoundaryState, errorBoundaryReducer } from "../../ErrorBoundary";


export const history = createBrowserHistory()


const rootReducer = (history: History): Reducer => combineReducers({
  router: connectRouter(history),
  userReducer: userReducerFromPackge,
  able: ableReducer,
  demo: demoReducer,
  authentication: authenticationReducer,
  errorBoundary: errorBoundaryReducer

})


export const store = createStore(
  rootReducer(history),
  applyMiddleware(
    routerMiddleware(history),
  )
)


export interface StoreState {
  router: RouterState,
  userReducer: UserState,
  able: typeof AbleState,
  demo: typeof DemoState,
  authentication: typeof AuthenticationState,
  errorBoundary: ErrorBoundaryState,
}
