import { APIClient } from "./DataAccess";
import { store } from "../redux/store";
import config from "../../config";
import userManager from "./UserManager";


export class APIClientFactory 
{


    private static _apiClient: APIClient;


    public static MustPerformOAuth(): Boolean 
    {
        const isOnRedirect = window.location.pathname.match(/redirect/);
        if (!isOnRedirect && !(store.getState() as any).userReducer.user) 
        {
            // userManager.signinRedirect({ state: { redirectPath: window.location.pathname } })
            return true;
        }
        return false;
    }


    public static getInstance(): APIClient 
    {
        if (APIClientFactory._apiClient === undefined) 
        {
            APIClientFactory._apiClient = new APIClient(config.apiPath);
        }
        return APIClientFactory._apiClient;
    }


}


export default APIClientFactory;
