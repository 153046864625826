import * as React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import userManager from "../../../shared/utils/UserManager";
import { Callback } from "react-oidc"
import { User } from "oidc-client";
import { store } from "../../../shared/redux/store";


const OAuthResponsePage = () => 
{
    const history = useHistory()

    const success = (user: User) => 
    {
        userManager.storeUser(user);
        window.sessionStorage.setItem("user", JSON.stringify(user));

        const redirectURL: string = window.sessionStorage.getItem("returnUrl") ?? "";
        history.push(redirectURL);
    }
    
    return (
        <>
            <Callback
                userManager={userManager}
                onSuccess={success}
                onError={ (error: any) => 
                {
                    console.error(error) 
                }}
                >
                <div>Redirecting...</div>
            </Callback>         
        </>
    );
};

export default OAuthResponsePage;
