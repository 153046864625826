import * as React from "react";
import { useEffect, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import routes from "../../../routes";
import useWindowSize from "../../../hooks/useWindowSize";

import * as actionTypes from "../../../store/actions";
import { StoreState } from "../../../shared/redux/store";


const FrontEndLayout = () => 
{
    const { windowWidth } = useWindowSize();
    const dispatch = useDispatch();
    const defaultPath = useSelector((state: StoreState) => state.able.defaultPath);
    const collapseMenu = useSelector((state: StoreState) => state.able.collapseMenu);
    const layout = useSelector((state: StoreState) => state.able.layout);
    const subLayout = useSelector((state: StoreState) => state.able.subLayout);

    useEffect(() => 
    {
        if (windowWidth > 992 && windowWidth <= 1024 && layout !== "horizontal") 
        {
            dispatch({ type: actionTypes.COLLAPSE_MENU });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const mobileOutClickHandler = () => 
    {
        if (windowWidth < 992 && collapseMenu) 
        {
            dispatch({ type: actionTypes.COLLAPSE_MENU });
        }
    };
    let mainClass = ["pcoded-wrapper"];
    if (layout === "horizontal" && subLayout === "horizontal-2") 
    {
        mainClass = [...mainClass, "container"];
    }

    return (
        <>
            <style>{"body { background-color: #ffffff; }"}</style>
            <Suspense fallback={<Loader />}>
                <Switch>
                    {routes.map((route, index) => 
                    {
                        return route.component ? (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props) => <route.component {...props} />}
                            />
                        ) : null;
                    })}
                    <Redirect from="/" to={defaultPath} />
                </Switch>
            </Suspense>
        </>
    );
};
export default FrontEndLayout;
