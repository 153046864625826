import * as React from "react";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import LINKS from "../../../../../store/constant";
import Avatar1 from "../../../../../assets/images/user/avatar-1.jpg";
import Avatar2 from "../../../../../assets/images/user/avatar-2.jpg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../../shared/redux/store";
interface NavRightProps {
    rtlLayout: boolean;
}
const NavRight = (props: NavRightProps) => 
{
    const personName = useSelector((state: StoreState) => state.authentication.personName);
    
    return (
        <>
            <ul className="navbar-nav ml-auto">
                <li>
                    <Dropdown drop={!props.rtlLayout ? "left" : "right"} className="dropdown drp-user" alignRight={!props.rtlLayout}>
                        <Dropdown.Toggle variant={"link"} id="dropdown-basic">
                            <i className="feather icon-user icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="profile-notification">
                            <ul className="pro-body">
                                <li><Link className="dropdown-item" to="/account/account/profile"><i className="feather icon-user"></i> Profile</Link></li>
                                <li><Link className="dropdown-item" to="/account/account/billing"><i className="feather icon-file-text"></i> Billing</Link></li>
                                <li><Link className="dropdown-item" to="/account/account/communications"><i className="feather icon-headphones"></i> Communications</Link></li>
                                <li><Link className="dropdown-item" to="/logout"><i className="feather icon-log-out"></i> Logout</Link></li>
                            </ul>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
        </>
    );
};
export default NavRight;
