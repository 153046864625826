import * as React from "react";


export const isJson = function(str: string | null): boolean
{
    if (str === null)
    {
        return false;
    }
    
    try 
    {
        JSON.parse(str);
    }
    catch (e) 
    {
        return false;
    }
    return true;
}


export const moneyString = function(value: number, numberOfDigits: number) : string
{
    var formatter = new Intl.NumberFormat("en-US", 
    {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: numberOfDigits,
        maximumFractionDigits: numberOfDigits
    });
    
    return formatter.format(value);
}


export const dateToMonthSelectionString = function(date: Date | undefined) : string | undefined
{
    if (date === undefined || date.getTime() === 0)
    {
        return undefined;
    }

    return  date.getFullYear().toString() +
        "-" +
        ("0" + (date.getMonth() + 1).toString()).slice(-2);
}


export const unixTimeStampToDate = function(unixTimestamp: number | undefined) : Date | undefined
{
    if (unixTimestamp === undefined || unixTimestamp === 0)
    {
        return undefined;
    }

    const milliseconds = unixTimestamp * 1000;

    return new Date(milliseconds) ;   
}


export const dateToUnixTimeStamp = function(date: Date | undefined) : number | undefined
{
    if (date === undefined)
    {
        return undefined;
    }

    return parseInt((date.getTime() / 1000).toFixed(0));
}


export const shortDateString = function(value: number) : string
{
    return (new Date(value * 1000)).toLocaleDateString("en-US");
}


export const getSiblings = function(source: Node) : Element[]
{
    let siblings: Element[] = []; 

    if(!source.parentNode) 
    {
        return siblings;
    }

    let children: HTMLCollection  = source.parentNode.children;

    for (let index: number = 0; index < children.length; index++)
    {
        let sibling = children[index];

        if (sibling.nodeType === 1 && sibling !== source) 
        {
            siblings.push(sibling);
        }
    }

    return siblings;
}


export const validate = function(source: Node)
{
    const siblingNode: ChildNode | null = source.nextSibling;
    if (siblingNode === null)
    {
        return;
    }
    const sibling: HTMLElement = (siblingNode as HTMLElement);

    let isValid: boolean = true;

    const sourceElement = (source as HTMLElement);
    const validationString = sourceElement.getAttribute("data-validation");
    if (validationString !== null)
    {
        const rules = JSON.parse(validationString);
    
        for (const rule of rules)
        {
            // console.log(rule);
            switch (Object.keys(rule)[0])
            {
                case "string":
                    const len: number = (source as HTMLInputElement).value?.length ?? 0;
                    if (typeof rule.string.min === "number" && len < rule.string.min)
                    {
                        isValid = false;
                    }
                    if (typeof rule.string.max === "number" && len > rule.string.max)
                    {
                        isValid = false;
                    }
                    break;
                case "email":
                    const emailValue: string = (source as HTMLInputElement).value ?? "";
                    const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                    isValid = regexEmail.test(emailValue);                    
                    break;
                case "not":
                    debugger;
                    const notValue: string = (source as HTMLInputElement).value ?? "";

                    isValid = (notValue !== rule["not"].value);
                    break;
                default:
                    break;
            }
        }
    }

    if (isValid)
    {
        sourceElement.classList.remove("error");
    }
    else
    {
        sourceElement.classList.add("error");
    }
    
    sibling.style.display = isValid ? "none" : "inline-block";
}