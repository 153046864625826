export enum LocalStorageSetting
{
    PENDING_CUSTOMER_ID = "pendingCustomerId",
    CURRENT_RESUME = "currentResume",
    CURRENT_EXPERIENCE = "experienceId",
    CURRENT_EDUCATION = "educationId"
}


interface ILocalStorage
{
    getItem(key: string): string | null;
    setItem(key: string, value: string): void;
    removeItem(key: string): void;
}


export default class LocalStorage
{
    private static _storage: ILocalStorage | null = null;
    
    public static getInstance()
    {
        if (this._storage === null)
        {
            this._storage = window.localStorage;
        }

        return this._storage;
    }


    public static get(key: LocalStorageSetting): string | null 
    {
      return this.getInstance().getItem(key);
    }
  
    public static set(key: LocalStorageSetting, value: string): void 
    {
        this.getInstance().setItem(key, value);
    }
  
    public static removeItem(key: LocalStorageSetting): void 
    {
        this.getInstance().removeItem(key);
    }
  
    public static removeItems(keys: LocalStorageSetting[]): void 
    {
      keys.forEach((key) => this.removeItem(key));
    }

  }