import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../../../hooks/useWindowSize";
import NavContent from "./NavContent";
import OutsideClick from "./OutsideClick";

import * as actionTypes from "./../../../../store/actions";
import navigation from "../menu-items";
import LINKS from "../../../../store/constant";
import Avatar2 from "../../../../assets/images/user/avatar-2.jpg";
import { StoreState } from "../../../../shared/redux/store";

const Navigation = () => 
{
    const { windowWidth } = useWindowSize();
    const dispatch = useDispatch();
    const personName = useSelector((state: StoreState) => state.authentication.personName);
    const layout = useSelector((state: StoreState) => state.able.layout);
    const subLayout = useSelector((state: StoreState) => state.able.subLayout);
    const collapseMenu = useSelector((state: StoreState) => state.able.collapseMenu);
    const layoutType = useSelector((state: StoreState) => state.able.layoutType);
    const rtlLayout = useSelector((state: StoreState) => state.able.rtlLayout);
    const navFixedLayout = useSelector((state: StoreState) => state.able.navFixedLayout);
    const headerFixedLayout = useSelector((state: StoreState) => state.able.headerFixedLayout);
    const boxLayout = useSelector((state: StoreState) => state.able.boxLayout);

    const onChangeLayout = (layout: string) => dispatch({ type: actionTypes.CHANGE_LAYOUT, layout: layout });
    const resize = () => 
    {
        const rootDom = document.getElementById("root");
        if (rootDom) 
        {
            const contentWidth = rootDom.clientWidth;
            if (layout === "horizontal" && contentWidth < 992) 
            {
                onChangeLayout("vertical");
            }
        }
    };

    useEffect(() => 
    {
        resize();
        window.addEventListener("resize", resize);
        return () => 
        {
            window.removeEventListener("resize", resize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scroll = () => 
    {
        if (navFixedLayout && !headerFixedLayout) 
        {
            const el = document.querySelector<HTMLDivElement>(".pcoded-navbar.menupos-fixed");
            if (!el) return;

            const scrollPosition = window.pageYOffset;
            if (scrollPosition > 60) 
            {
                el.style.position = "fixed";
                el.style.transition = "none";
                el.style.marginTop = "0";
            }
            else 
            {
                el.style.position = "absolute";
                el.style.marginTop = "60px";
            }
        }
        else 
        {
            const navBarDom = document.querySelector(".pcoded-navbar");
            if (navBarDom) navBarDom.removeAttribute("style");
        }
    };

    let navClass = ["pcoded-navbar"];
    navClass = [...navClass, layoutType];

    if (layout === "horizontal") 
    {
        navClass = [...navClass, "theme-horizontal"];
    }
    else 
    {
        if (navFixedLayout) 
        {
            navClass = [...navClass, "menupos-fixed"];
        }

        if (navFixedLayout && !headerFixedLayout) 
        {
            window.addEventListener("scroll", scroll, true);
            /*window.scrollTo(0, 0);*/
        }
        else 
        {
            window.removeEventListener("scroll", scroll, false);
        }
    }

    if (windowWidth < 992 && collapseMenu) 
    {
        navClass = [...navClass, "mob-open"];
    }
    else if (collapseMenu) 
    {
        navClass = [...navClass, "navbar-collapsed"];
    }

    if (layoutType === "dark") 
    {
        document.body.classList.add("able-pro-dark");
    }
    else 
    {
        document.body.classList.remove("able-pro-dark");
    }

    if (rtlLayout) 
    {
        document.body.classList.add("able-pro-rtl");
    }
    else 
    {
        document.body.classList.remove("able-pro-rtl");
    }

    if (boxLayout) 
    {
        document.body.classList.add("container");
        document.body.classList.add("box-layout");
    }
    else 
    {
        document.body.classList.remove("container");
        document.body.classList.remove("box-layout");
    }

    let navBarClass = ["navbar-wrapper"];
    if (layout === "horizontal" && subLayout === "horizontal-2") 
    {
        navBarClass = [...navBarClass, "container"];
    }

    return (
        <nav className={navClass.join(" ")}>
            {windowWidth < 992 ? (
                <OutsideClick>
                    <div className="navbar-wrapper">
                        <NavContent navigation={navigation.items} />
                    </div>
                </OutsideClick>
            ) : (
                <div className={navBarClass.join(" ")}>
                    <div className="main-menu-header" style={{height: "auto"}}>
                        {/* <img src={Avatar2} className="img-radius" alt="User Profile" /> */}
						<div className="user-details">
							<div id="more-details">{personName}</div>
						</div>
					</div>

                    <NavContent navigation={navigation.items} />
                </div>
            )}
        </nav>
    );
};

export default Navigation;
